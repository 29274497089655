// Grid system
.main {
  @include make-col-ready();
  @include media-breakpoint-up(sm) {
    @include make-col($main-sm-columns);
  }
  @include media-breakpoint-up(md) {
    @include make-col($main-md-columns);
    .sidebar-primary & {
      @include make-col($main-md-columns - $sidebar-md-columns);
    }
  }
}

.sidebar {
  align-content: flex-start;
  @include make-col-ready();
  @include media-breakpoint-up(sm) {
    @include make-col($sidebar-sm-columns);
  }
  @include media-breakpoint-up(md) {
    @include make-col($sidebar-md-columns);
  }
}
