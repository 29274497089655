#bs-logo {
  width: 50px;
}

#bs-blog-name {
  font-size: 1.6em;
  font-weight: 100;
}

header.banner {
  min-height: 100px;
  padding-top: 20px;
  background: #fff;
  margin-bottom: 2em;

  .nav-primary {
    float: right;
    padding-top: 15px;
    padding-bottom: 15px;

    li {
      margin-right: .7rem;
      padding-right: .7rem;
      text-transform: uppercase;
      font-weight: 200;
      border-right: 1px solid #dcdcdc;

      &:last-child {
        border-right: none;
      }

      a {
        color: #000;

        &:hover {
          border-bottom-width: 3px;
          border-bottom-style: solid;
          border-bottom-color: #E47E7F;
          text-decoration: none;
        }
      }
    }
  }

  a.brand {
    color: #000;
    font-weight: 100;

    h2 {
      padding-top: 10px;
      padding-left: 15px;
    }
  }
}

