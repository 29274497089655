
body {
  ///* IE10+ */
  //background-image: -ms-radial-gradient(left top, ellipse farthest-corner, #C5E7F7 0%, #FFFFFF 56%, #FFFFFF 100%);
  //
  ///* Mozilla Firefox */
  //background-image: -moz-radial-gradient(left top, ellipse farthest-corner, #C5E7F7 0%, #FFFFFF 56%, #FFFFFF 100%);
  //
  ///* Opera */
  //background-image: -o-radial-gradient(left top, ellipse farthest-corner, #C5E7F7 0%, #FFFFFF 56%, #FFFFFF 100%);
  //
  ///* Webkit (Safari/Chrome 10) */
  //background-image: -webkit-gradient(radial, left top, 0, left top, 572, color-stop(0, #C5E7F7), color-stop(56, #FFFFFF), color-stop(100, #FFFFFF));
  //
  ///* Webkit (Chrome 11+) */
  //background-image: -webkit-radial-gradient(left top, ellipse farthest-corner, #C5E7F7 0%, #FFFFFF 56%, #FFFFFF 100%);
  //
  ///* W3C Markup */
  //background-image: radial-gradient(ellipse farthest-corner at left top, #C5E7F7 0%, #FFFFFF 56%, #FFFFFF 100%);
  //
  //background-repeat: no-repeat;

  background: #E8F5FC;
}

body#tinymce {
  background: none;
}

.widget {
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid dotted $bsc-sky;
}

.page-header h1 {
  font-family: "vista-sans-narrow", sans-serif;
  font-size: 2rem;
  font-weight: 300;
  margin-bottom: 2rem;
}

article {
  font-size: 1.13rem;
}

.article-meta {
  font-size: 1rem;
  margin: 1rem 0;
  opacity: 0.6;
}

article {

  h2, h3, h4 {
    font-family: "vista-sans-narrow", sans-serif;
    margin-bottom: .6rem;
    margin-top: 1.8rem;
    font-weight: 700;
  }

  .entry-title {

    margin-top: 0;
    font-weight: 800;

    a:link,
    a:visited,
    a:hover,
    a:focus,
    a:active {
      color: $bsc-bark;
    }
  }

  .entry-subtitle {
    font-weight: 100;
    color: $gray-600;
    margin-top: 0;
  }

  blockquote {
    margin: 1rem 0 1rem 2rem;
    color: #555;
    font-size: 1.2rem;
    padding-left: .5rem;
    border-left: 2px solid rgba(0, 0, 0, 0.2);
  }
}

.home article.post + article.post { // Do not apply to first article in list
  padding-top: 1rem;
  margin-top: 1rem;
  border-top: 1px dashed $bsc-sky;
}

.post-meta {
  &, * {
    color: $gray-500;
  }
}

// Hiding Latest Posts on front

.home .page-header {
  display: none;
}

// Post lede

.single-post, .page {
  article p.lede {
    font-weight: 600;
    font-size: 110%;
    opacity: .8;
    letter-spacing: -0.2px;
  }
}

// Add bottom padding to cookie notice box so that its contents aren't covered by social sharing buttons.
@media only screen and (max-width: 767px) {
  .cookie-notice-container {
    padding-bottom: 70px;
  }
}

@media only screen and (min-width: 768px) {
  .cookie-notice-container {
    font-size: 135%;

    .cn-set-cookie {
      font-size: 100% !important;
      padding: 10px !important;
    }
  }
}


.cn-more-info {
  border: none !important;
  background: transparent !important;
  text-shadow: none !important;
  opacity: .8 !important;
}

// Hide logo for mobile

@media screen and (max-width: 1200px) {
  header.banner {
    display: none; // hide it
  }
}

// Add space above content in responsive mode

@media screen and (max-width: 1200px) {
  .wrap.container {
    margin-top: 22px;
  }
}

// Adjust left side margin for slicknav menu

//.slicknav_nav ul {
//  margin-left: 60px;
//}

.slicknav_menu .slicknav_brand img {
  height: 100%;
}


// Pocket and Instapaper icons

.bs-save-promote {

  border: 2px solid rgba(0, 0, 0, 0.1);
  padding: 1.3em;
  margin-bottom: 1em;
  font-size: 40%;
  border-radius: 1em;

  h5 {
    margin-bottom: .5em;
    font-size: 18px;
  }

  .bs-save-icon {
    width: 128px;
    height: 36px;
    font-size: 12px;
    color: #000;
    padding: 4px 4px 4px 36px;
    display: block;
    float: left;

    span {
      display: inline-block;
      vertical-align: middle;
      line-height: normal;
    }

    &:hover {
      background-color: rgba(255, 255, 255, 0.4);
    }
  }

  .bs-save-icon-pocket-container {
    padding-top: 6px;
  }

  .bs-save-icon-instapaper {
    background-image: url('/wp-content/themes/bondsai-blog/assets/images/bs-icon-instapaper.png');
    background-position: 4px 50%;
    background-repeat: no-repeat;
    background-size: 28px 28px;
  }

}

.bs-like {

  padding: 1.3em;
  margin: 1em 0 2em 0;
  font-size: 40%;
  background: rgba(255, 255, 255, 0.8);
  border: 2px solid #fff;
  border-radius: 1em;

  h5 {
    margin-bottom: .5em;
    font-size: 18px;
    font-weight: bold;
  }
}

.bblog-box1 {
  background: #fff;
  border: 1px solid #d1d1d1;
  padding: 1rem;
  margin: .5rem 0;

  h2:first-child,
  h3:first-child,
  h4:first-child,
  h5:first-child,
  h6:first-child {
    margin-top: 0;
  }
}

/**
 * Links
 */
body.home p.lede,
body.page article.page,
body.single-post article.post .entry-content {
  a {
    &:link {
      border-bottom: 1px dashed rgba($bsc-leaf, 0.7);
      font-weight: 500; // Medium
    }

    &:hover,
    &:active,
    &:focus {
      text-decoration: none;
      color: $bsc-leaf;
      border-bottom: 1px solid $bsc-leaf;
    }

    &:focus {
      background-color: #fff;
    }
  }
}

// Override idiotic logo max width from Slicknav plugin

.slicknav_brand {
  max-width: none !important;
  padding-right: 12px;
}

// Pagination
nav.navigation.posts-navigation {
  font-weight: bold;
  padding: .5em 0 0 0;
  border-top: 1px solid #ccc;
}

.widget .wpp-list {
  padding-left: 0;
  margin-top: 1em;

  li {
    margin-bottom: .2em;

    a {
      font-weight: 500;

      &:focus {
        background: #fff;
      }
    }

    &:not(:last-child) {
      margin-bottom: .5em;
      padding-bottom: .5em;
    }

    .wpp-thumbnail {
      margin: 0 .6em 0 0;
    }

  }
}

.entry-content {
  ul {
    list-style: none;

    li {
      margin: .6rem 0;
      position: relative;
    }

    li:before {
      content: " ";
      background-size: cover;
      background-image: url("/wp-content/themes/bondsai-blog/assets/images/bs-bullet.svg");
      width: 1rem;
      height: 1rem;
      position: absolute;
      left: -1.6rem;
      top: .42rem;
    }
  }
}

@media screen and (min-width: 576px) and (max-width: 1140px) {
  .wrap.container {
    margin-left: 48px;
  }
}

.nav-links {

  font-size: 1.3rem;

  &:after {
    content: '';
    display: block;
    clear: both;
  }

  div {
    background: #fff;
    padding: .4rem .7rem;
    border-radius: 4px;
    width: 48%;
    margin: .5%;
    margin-bottom: 2rem;
  }

  .nav-previous {
    float: left;
    text-align: left;
    margin-left: 0;
    &:before {
      content: "← "
    }
  }

  .nav-next {
    float:right;
    text-align: right;
    margin-right: 0;
    &:after {
      content: " →"
    }
  }
}

.post-series-box {
  padding: .3rem 1rem;
  background: #fff;
  border-radius: 4px;
  margin: 1rem 0;
}

.post-series-entry {
  background: #fff url(../../assets/images/books.svg) no-repeat;
  background-position: 0px;
  padding: 1rem 0;
  padding-left: 56px;
  border-radius: 4px;
}

.post-series-box .post-series-entry {
  font-weight: bold;
}

.post-series-prev {
  float: left;
  width: 50%;
    margin-bottom: .5rem;
  p {
        margin-bottom: 0;
  }
}

.post-series-next {
  float: right;
  width: 50%;
  margin-bottom: .5rem;
  text-align: right;
  p {
    margin-bottom: 0;
  }
}
