article.post {
  .guerrillawrap {
    background: #fff;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
    border: none;
    float: none;
    padding: 2%;
    width: 100%;
    clear: both;

  }

  .guerrillagravatar {
    float: left;
    margin: 0 30px 0 0;
    width: 10%;
  }

  .guerrillagravatar img {
    border-radius: 50%;
    border: 1px solid #eee;
  }

  .guerrillatext {
    width: 84%;

    h4 {
      font-weight: normal;
      span {
        display: block;
        font-weight: bold;
        font-size: 130%;
        margin: .2em 0 .8em 0;
      }
    }
  }

  .guerrillatext h4 {
    font-size: 20px;
    line-height: 20px;
    margin: 0 0 0 0;
    padding: 0;
  }

  .guerrillatext p {
    margin: 10px 0 15px 0;
  }

  .guerrillasocial {
    clear: both;
    float: none;
    width: 100%;
    margin-top: 1rem;
    // margin-left: 10%;
    // padding-left: 30px;

  }

  .guerrillasocial a {
    border: 0;
    margin-right: 10px;
  }
}

