h1.entry-title {
  font-weight: bold;
  font-family: "vista-sans-narrow";
}

section#comments {
  border-top: 1px dotted $bsc-sky;
  padding-top: 1rem;
  margin-top: 1rem;

  .comment-body {
    font-size: .8rem;
    border: 1px dashed $bsc-sky;
    padding: .5rem;
    border-radius: $border-radius;
    background: rgba(255, 255, 255, 0.7);
  }
}

/*
 Better handling of aligned images on mobile devices
 */
@media only screen and (max-width: 767px) {
  figure.alignleft, img.alignleft, figure.alignright, img.alignright {
    float:none;
    width: 100%;
  }
}


/*
 Scalable embedded videos
 */
.video-container {
  position:relative;
  padding-bottom:56.25%;
  padding-top:30px;
  height:0;
  overflow:hidden;
}

.video-container iframe, .video-container object, .video-container embed {
  position:absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
}